var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-order-container"
  }, [_c('van-row', {
    staticClass: "review-order-container__header"
  }, [_c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_vm._v(_vm._s(_vm.orderNum))]), _c('van-col', {
    staticClass: "review-order-container__right-item",
    attrs: {
      "span": "12"
    }
  }, [_vm._v(_vm._s(_vm.datetime))])], 1), _c('van-row', {
    staticClass: "review-order-container__content"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.lineName))]), _c('van-col', {
    staticClass: "review-order-container__right-item",
    attrs: {
      "span": "16"
    }
  }, [_vm._v(_vm._s(_vm.clientName))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }