var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": '备货复核(' + _vm.$route.query.line_name + ')',
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "review-container__list"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.load
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('review-order', {
      key: index,
      attrs: {
        "line-name": order.line ? order.line.name + '线' : '无线路',
        "datetime": order.created_at,
        "order-num": order.num,
        "client-name": order.client.name
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.handleClick(order);
        }
      }
    });
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }