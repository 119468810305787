<template>
  <div class="review-container">
    <my-nav-bar
      :title="'备货复核(' + $route.query.line_name + ')'"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div class="review-container__list">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="load"
        >
          <review-order
            v-for="(order, index) in orders"
            :key="index"
            :line-name="order.line ? order.line.name + '线' : '无线路'"
            :datetime="order.created_at"
            :order-num="order.num"
            :client-name="order.client.name"
            @click.native="handleClick(order)"
          />
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewOrder from './components/order'
import reviewApi from '@/api/review'
import MyNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'ReviewList',
  components: { ReviewOrder, MyNavBar },
  data() {
    return {
      orders: [],
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      showEmpty: false
    }
  },
  methods: {
    load() {
      this.page++
      this.getOrders()
    },
    getOrders() {
      const params = {
        page: this.page,
        limit: this.limit,
        line_id: this.$route.query.line_id,
        mode: this.$route.query.mode
      }
      reviewApi.order(params).then(res => {
        this.orders = this.orders.concat(res.data.list)
        this.loading = false
        this.showEmpty = this.orders.length === 0
        if (res.data.list.length === 0) { this.finished = true }
      })
    },
    handleClick(order) {
      this.$router.push({ path: '/review-detail', query: { order_id: order.id }})
    }
  }
}
</script>

<style lang="scss" scoped>
  .review-container {
    &__list {
      padding: 15px 15px 5px 15px;
    }
  }
</style>
